import React from 'react';
import Link from 'components/Link';
import { useTranslation } from 'react-i18next';
import { experiments, VariationTypes } from 'containers/ExperimentalFeature';
import { useExperimentalFeature } from 'hooks/useExperimentalFeature';
import { routes } from 'utils/routes';
import { track, Events } from 'utils/analytics';
import CTALink from '../CTALink';
import { Wrapper, Container, ContainerItem } from './InlineCTA.style';

const trackClickEvent = (trackingKey) => (text) => {
    track(
        Events.BUTTONCLICK.create({
            description: 'Homepage main CTAs',
            item: `${text} - Inline ${trackingKey}`,
        })
    );
};

const wizardSearchParams = new URLSearchParams(
    `t=${JSON.stringify({ wizardTrigger: 'Homepage CTAs' })}`
);

const wizardQueryString = `?${wizardSearchParams.toString()}`;

const InlineCTA = ({ trackingKey, isLoggedIn }) => {
    const { t } = useTranslation();
    const handleClick = trackClickEvent(trackingKey);
    const [homePageCTAFeature] = useExperimentalFeature(experiments.HOMEPAGE_CTA);
    const isHomePageCTAInControl = homePageCTAFeature === VariationTypes.CONTROL;
    const searchProfilesTo = (() => {
        if (isLoggedIn) {
            return routes.search.profiles();
        }

        return isHomePageCTAInControl
            ? `${routes.search.findASitterWizard()}${wizardQueryString}`
            : routes.join.petParents();
    })();

    return (
        <Wrapper>
            <Container>
                <ContainerItem data-testid="inline-cta-find-a-sitter-button">
                    <CTALink
                        t={t}
                        variant={Link.Variant.Button.PRIMARY}
                        label={t(
                            isHomePageCTAInControl || isLoggedIn
                                ? 'pages_home_CTA_find_a_sitter'
                                : 'pages_home_hero_homepage_cta_test_search_sitters'
                        )}
                        to={searchProfilesTo}
                        chevronRight={{ size: 'medLarge' }}
                        onClick={() =>
                            handleClick(
                                t(
                                    isHomePageCTAInControl || isLoggedIn
                                        ? 'pages_home_CTA_find_a_sitter'
                                        : 'pages_home_hero_homepage_cta_test_search_sitters'
                                )
                            )
                        }
                    />
                </ContainerItem>
                <ContainerItem data-testid="inline-cta-find-a-house-sit-button">
                    <CTALink
                        t={t}
                        variant={Link.Variant.Button.PRIMARY}
                        label={t('pages_home_CTA_find_a_house_sit')}
                        to={
                            !isLoggedIn
                                ? `${routes.search.findAHouseSitWizard()}${wizardQueryString}`
                                : routes.search.listings()
                        }
                        chevronRight={{ size: 'medLarge' }}
                        onClick={() => handleClick(t('pages_home_CTA_find_a_house_sit'))}
                    />
                </ContainerItem>
            </Container>
        </Wrapper>
    );
};

export default InlineCTA;
