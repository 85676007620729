import styled, { css } from 'styled-components';
import Link from 'components/Link';
import { IconChevronRight } from 'components/Icon';

const CTALinkStyled = styled(Link)(
    ({ chevronRight, theme: { spacing, screenSize } }) => css`
        padding: ${spacing[1.5]} ${spacing[1]} ${spacing[1.5]} ${spacing[2]};
        width: 100%;
        ${chevronRight ? 'text-align: left;' : null};
        @media screen and (min-width: ${screenSize.smallTablet}px) {
            padding: ${spacing[3]};
            padding-right: ${spacing[2]};
        }
    `
);

const CTALinkLabelStyled = styled.span(
    ({ theme: { screenSize, typography, fontWeights } }) => css`
        flex: 1;
        @media screen and (min-width: ${screenSize.smallTablet}px) {
            ${typography.focalSmall};
            font-weight: ${fontWeights.bold};
        }
    `
);

const CTAIconChevronRightStyled = styled(IconChevronRight)(
    ({ theme: { palette } }) => css`
        & svg {
            fill: ${palette.highlight};
        }
    `
);

export { CTALinkStyled, CTAIconChevronRightStyled, CTALinkLabelStyled };
