/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { H3, P } from 'typography';

const WrapperStyled = styled.div(
    () => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
    `
);

export const HeadingStyled = styled.h2`
    ${({ theme }) => theme.typography.h2};
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    text-align: center;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        ${({ theme }) => theme.typography.h2Large};
        margin-bottom: ${({ theme }) => theme.spacing[6]};
    }
`;

const Image = styled('img')(
    ({ theme: { screenSize } }) => css`
        margin: auto;
        display: block;
        height: 128px;

        /* 1280 */
        @media screen and (min-width: ${screenSize.smallDesktop}px) {
            height: 170px;
        }
    `
);

const Title = styled(H3)(
    ({ theme: { spacing, screenSize, typography } }) => css`
        text-align: center;
        margin: 0;
        margin-top: ${spacing[2]};

        /* 960 */
        @media screen and (min-width: ${screenSize.smallDesktop}px) {
            margin-top: ${spacing[4]};
        }
        /* 1280 */
        @media screen and (min-width: ${screenSize.largeDesktop}px) {
            ${typography.h2};
            margin-top: ${spacing[6]};
        }
    `
);

const Body = styled(P)(
    ({ theme: { spacing, screenSize, typography } }) => css`
        text-align: center;
        margin: 0 auto;
        margin-top: ${spacing[2]};

        /* 480 */
        @media screen and (min-width: ${screenSize.largeHandset}px) {
            max-width: 60%;
        }
        /* 600 */
        @media screen and (min-width: ${screenSize.smallTablet}px) {
            max-width: 50%;
        }
        /* 720 */
        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            max-width: 85%;
        }
        /* 800 */
        @media screen and (min-width: ${screenSize.largeTablet}px) {
            max-width: 75%;
        }
        /* 960 */
        @media screen and (min-width: ${screenSize.smallDesktop}px) {
            max-width: 350px;
        }
        /* 1280 */
        @media screen and (min-width: ${screenSize.largeDesktop}px) {
            ${typography.span};
            margin-top: ${spacing[3]};
        }
    `
);

export { WrapperStyled, Image, Title, Body };
