import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme: { spacing, palette, screenSize } }) => css`
        background: ${palette.primarySoft};
        margin: 0 auto;
        padding: ${spacing[3]};
        margin: ${spacing[3]} ${spacing[0]};

        @media screen and (min-width: ${screenSize.smallTablet}px) {
            padding: ${spacing[6]};
            margin: ${spacing[2]} ${spacing[0]};
            &:last-child {
                margin-bottom: ${spacing[8]};
            }
        }
    `
);

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallTablet}px) {
        flex-direction: row;
    }
`;

export const ContainerItem = styled.div`
    width: 100%;

    &:not(:last-child) {
        margin-right: 0;
        margin-bottom: ${({ theme }) => theme.spacing[2]};
    }

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallTablet}px) {
        width: 260px;

        &:not(:last-child) {
            margin-right: ${({ theme }) => theme.spacing[4]};
            margin-bottom: 0;
        }
    }
`;
