import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlexboxGrid, Col } from 'components/layouts';
import PropTypes from 'prop-types';

import { WrapperStyled, HeadingStyled, Image, Title, Body } from './HowItWorks.style';
import betterForOwnersImage from './assets/better-for-owners.svg';
import betterForPetsImage from './assets/better-for-pets.svg';
import betterForSittersImage from './assets/better-for-sitters.svg';

const HowItWorks = ({ heading }) => {
    const { t } = useTranslation();

    return (
        <WrapperStyled>
            <HeadingStyled>{t(heading)}</HeadingStyled>

            <FlexboxGrid
                justify={FlexboxGrid.Justify.CENTER}
                config={{
                    base: {
                        verticalSpacing: 24,
                        horizontalSpacing: 0,
                    },
                    mediumTablet: {
                        verticalSpacing: 32,
                        horizontalSpacing: 48,
                    },
                    largeDesktop: {
                        verticalSpacing: 56,
                        horizontalSpacing: 56,
                    },
                    wide: {
                        verticalSpacing: 56,
                        horizontalSpacing: 48,
                    },
                }}
            >
                <Col span={12} mediumTablet={6} smallDesktop={4}>
                    <Image src={betterForOwnersImage} alt="graphic - person and dog posing" />
                    <Title>{t('pages_home_OwnerFocus_howItWorks_better_for_owners_heading')}</Title>
                    <Body>{t('pages_home_OwnerFocus_howItWorks_better_for_owners_body')}</Body>
                </Col>

                <Col span={12} mediumTablet={6} smallDesktop={4}>
                    <Image
                        src={betterForPetsImage}
                        alt="graphic - person petting dog while kneeling"
                    />
                    <Title>{t('pages_home_OwnerFocus_howItWorks_better_for_pets_heading')}</Title>
                    <Body>{t('pages_home_OwnerFocus_howItWorks_better_for_pets_body')}</Body>
                </Col>

                <Col span={12} mediumTablet={6} smallDesktop={4}>
                    <Image src={betterForSittersImage} alt="graphic - cat in a basket" />
                    <Title>
                        {t('pages_home_OwnerFocus_howItWorks_better_for_sitters_heading')}
                    </Title>
                    <Body>{t('pages_home_OwnerFocus_howItWorks_better_for_sitters_body')}</Body>
                </Col>
            </FlexboxGrid>
        </WrapperStyled>
    );
};

HowItWorks.propTypes = {
    heading: PropTypes.string,
};

HowItWorks.defaultProps = {
    heading: 'pages_home_OwnerFocus_howItWorks_heading',
};
export default HowItWorks;
