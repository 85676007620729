import React from 'react';
import Link from 'components/Link';

import { CTALinkStyled, CTAIconChevronRightStyled, CTALinkLabelStyled } from './CTALink.style';

const CTALink = ({ variant, label, to, chevronRight, onClick }) => (
    <CTALinkStyled variant={variant} to={to} onClick={onClick} chevronRight={!!chevronRight}>
        <CTALinkLabelStyled>{label}</CTALinkLabelStyled>
        {chevronRight && (
            <CTAIconChevronRightStyled
                size={chevronRight.size}
                palette={variant === Link.Variant.Button.PRIMARY ? 'highlight' : 'primaryDark'}
            />
        )}
    </CTALinkStyled>
);

export default CTALink;
