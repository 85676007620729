import styled, { css } from 'styled-components';

// fullWidth prop removes outer padding when on mobile
export const Section = styled.section`
    padding-top: ${({ theme, top }) => (top ? theme.spacing[3] : null)};
    padding-bottom: ${({ theme, bottom }) => (bottom ? theme.spacing[3] : null)};

    padding-left: ${({ theme, fullWidth }) => (!fullWidth ? theme.spacing[3] : null)};
    padding-right: ${({ theme, fullWidth }) => (!fullWidth ? theme.spacing[3] : null)};

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        padding-top: ${({ theme, top }) => (top ? theme.spacing[6] : null)};
        padding-bottom: ${({ theme, bottom }) => (bottom ? theme.spacing[6] : null)};

        padding-left: ${({ theme }) => theme.spacing[3]};
        padding-right: ${({ theme }) => theme.spacing[3]};
    }

    /* 1024 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        padding-top: ${({ theme, top }) => (top ? theme.spacing[6] : null)};
        padding-bottom: ${({ theme, bottom }) => (bottom ? theme.spacing[6] : null)};

        padding-left: ${({ theme }) => theme.spacing[4]};
        padding-right: ${({ theme }) => theme.spacing[4]};
    }

    /* 1280 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        padding-top: ${({ theme, top }) => (top ? theme.spacing[6] : null)};
        padding-bottom: ${({ theme, bottom }) => (bottom ? theme.spacing[6] : null)};

        padding-left: ${({ theme }) => theme.spacing[5]};
        padding-right: ${({ theme }) => theme.spacing[5]};
    }
`;

export const SectionHowItWorks = styled(Section)`
    margin: 0 auto;
    max-width 1440px;
    padding-top: ${({ theme, top }) => (top ? theme.spacing[5] : null)};

    /* 375 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumHandset}px) {
        padding-top: ${({ theme, top }) => (top ? theme.spacing[0] : null)};
    }

    /* 480 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeHandset}px) {
        padding-top: ${({ theme, top }) => (top ? theme.spacing[13] : null)};
    }

    /* 600 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallTablet}px) {
        padding-top: ${({ theme, top }) => (top ? theme.spacing[3] : null)};
    }

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        padding-top: ${({ theme, top }) => (top ? theme.spacing[12] : null)};
    }

    /* 960 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        padding-top: ${({ theme, top }) => (top ? theme.spacing[6] : null)};
        padding-left: ${({ theme }) => theme.spacing[6]};
        padding-right: ${({ theme }) => theme.spacing[6]};
    }

    /* 1280 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        padding-left: ${({ theme }) => theme.spacing[9]};
        padding-right: ${({ theme }) => theme.spacing[9]};
    }

    /* 1440 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.wide}px) {
        padding-left: 144px;
        padding-right: 144px;
    }
`;

export default Section;
