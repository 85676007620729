import { connect } from 'react-redux';
import { getIsLoggedIn } from 'src/shared/selectors';

import InlineCTA from './InlineCTA';

const mapStateToProps = (state) => ({
    isLoggedIn: getIsLoggedIn(state),
});

export default connect(mapStateToProps)(InlineCTA);
